/* line 331, ../sass/home.scss */
section.faq {
  text-align: center;
}
@media only screen and (max-width: 640px) {
  /* line 335, ../sass/home.scss */
  section.faq {
    padding: 0 20px;
  }
}

/* line 335, ../sass/home.scss */
section.faq ul.questions {
  margin-top: 40px;
  -moz-column-count: 2;
  -webkit-column-count: 2;
  column-count: 2;
  -moz-column-gap: 20px;
  -webkit-column-gap: 20px;
  column-gap: 20px;
}
@media only screen and (max-width: 640px) {
  /* line 335, ../sass/home.scss */
  section.faq ul.questions {
    margin: 0;
    -moz-column-count: initial;
    -webkit-column-count: initial;
    column-count: initial;
    -moz-column-gap: initial;
    -webkit-column-gap: initial;
    column-gap: initial;
  }
}

/* line 349, ../sass/home.scss */
section.faq li.question {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #e6e6e6;
}
@media only screen and (max-width: 640px) {
  /* line 349, ../sass/home.scss */
  section.faq li.question {
    display: block;
    width: 100%;
    margin: 20px auto;
  }
}

/* line 366, ../sass/home.scss */
section.faq li.question h3 {
  margin-bottom: 10px;
  font-family: "Volkswagen Head";
  font-size: 14px;
  line-height: 18px;
  color: #04435e;
  text-align: left;
}
@media only screen and (max-width: 640px) {
  /* line 366, ../sass/home.scss */
  section.faq li.question h3 {
    /* text-align: center; */
  }
}

/* line 379, ../sass/home.scss */
section.faq li.question p {
  font-weight: 100;
  font-size: 12px;
  line-height: 16px;
  color: #04435e;
}
@media only screen and (max-width: 640px) {
  /* line 379, ../sass/home.scss */
  section.faq li.question p {
    /* text-align: center; */
  }
}

/* line 390, ../sass/home.scss */
section.faq .btn_faq {
  display: inline-block;
  margin: 30px 0 60px 0;
  padding: 10px 20px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  background-color: #87173d;
  -moz-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media only screen and (max-width: 640px) {
  /* line 390, ../sass/home.scss */
  section.faq .btn_faq {
    display: block;
    padding: 30px 0;
  }
}

/* line 408, ../sass/home.scss */
section.faq .btn_faq:hover,
section.faq .btn_faq:focus {
  background-color: #61102b;
}

/* line 413, ../sass/home.scss */
section.faq .btn_faq img {
  margin-right: 10px;
  margin-top: -3px;
}




li.article h3{
  margin-bottom: 20px;
}


section.faq button{
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  background-color: #ebf0f2;
  border: none;
  color: #646464;
  outline: none;
  width: 25px;
  height: 25px;
}

section.faq h3{
  width: calc(100% - 25px);
  vertical-align: middle;
}

section.faq p{
  padding-right: 45px;
}

section.faq ul.questions li{
  position: relative;
}
