* {
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;

  &:before,
  &:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

body, html {
  width: 100%;
  height: 100%;
  font-family: $font-vw-head;
  font-weight: normal;
  letter-spacing: -0.25px;
  background-color: #ebf0f2;
}

html {
  -webkit-overflow-scrolling: touch;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

section {
  // width: 100%;
  // overflow: hidden;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

strong, b {
  font-weight: bold;
}

i, em {
  font-style: italic;
}

a {
  text-decoration: none;
  color: #FFF;
}

.valign {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 1px;
}

.inner,
.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.vw-cookie-alert {
  color:#757575;
  font-size:16px;
  line-height:1.5;
  position: fixed;
  width: 100%;
  min-height: 70px;
  padding: 20px 60px 20px 20px;
  bottom: 0;
  font-weight: lighter;
  background-color: #e5e5e5;
  border-top: 1px solid #ccc;
  z-index: 500;

  a {
    color: #87173d;
  }

  button {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 30px;
    width: 30px;
    padding: 0;
    border: 0;
    line-height: 30px;
    font-family: $font-vw-text;
    text-align: center;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
  }
}

.g-recaptcha {
  background-color:#fff;
  padding:10px;
  margin: 10px 0;

  div {
    margin: 0 auto;
  }
}

section.form .error_form {
  display:none;
}

.marques {
  width: 400px;
  text-align:right;
  letter-spacing: normal;
}


footer {
  margin-top: 60px;

  table {
    // display: inline-block;
    vertical-align: middle;
  }
}

/*
.n_vert {
  text-align: center;
  padding: 20px 0 0;
  background-color: #fff;

  span {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    color: #646464;
    margin-right: 20px;
  }

  strong {
    a {
      padding: 0 10px;
    }
  }

  @media only screen and (max-width: 1200px) {
    padding: 20px 10px;
  }
}
*/

.bottom_footer {
  padding: 20px 0;
  background-color: #00405b;

  a {
    font-size: 12px;
    color: #fff;
    margin-left: 40px;

    &:first-child {
      margin-left: 0;
    }
  }

  .footer_right {
    float: right;
  }

  @media only screen and (max-width: 1200px) {
    padding: 20px 10px;
  }

  @media only screen and (max-width: 640px) {
    .footer_right {
      float: none;
    }

    a {
      display: block;
      text-align: center;
      font-size: 14px;
      margin: 20px 0;
    }
  }
}

header {
  background-color: #fff;
  border-bottom: 2px solid #e6e6e6;

  @media only screen and (max-width: 640px) {
    text-align: center;
  }
}

.top_head {
  background-color: #00405b;
  text-align: right;
  padding: 7px 0;

  a {
    font-size: 12px;
    color: #fff;
  }

  @media only screen and (max-width: 640px) {
    text-align: center;
  }

  @media only screen and (max-width: 1200px) {
    padding: 15px 10px;
  }
}

.marques {
  display: inline-block;
  vertical-align: middle;
  float: right;
  margin-top: 15px;
  letter-spacing: normal;

  @media only screen and (max-width: 640px) {
    float: none;
    margin: 15px 0;
    letter-spacing: normal;
  }
}


.marque {
  margin: 0 10px;
}


.intro {
  margin: 60px auto;
  font-weight: 100;
  font-size: 25px;
  line-height: 28px;
  color: #00405b;

  @media only screen and (max-width: 640px) {
    margin: 60px auto;
    text-align: center;
    font-size: 18px;
    padding: 0 20px;
  }

  @media only screen and (max-width: 960px) {
    margin: 60px auto;
    text-align: center;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 20px;
  }
}

h1 {
  font-family: $font-vw-head;
  font-weight: 700;
  font-size: 50px;
  margin: 10px 0;
  line-height: 49px;

  @media only screen and (max-width: 640px) {
    font-size: 30px;
    line-height: inherit;
  }
}

section.com {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-right: 1%;

  @media only screen and (max-width: 960px) {
    width: 100%;
    margin: 0;
    padding: 0 20px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 20px;
  }
}

h2 {
  font-family: $font-vw-head;
  font-weight: 100;
  font-size: 24px;
  color: #00405b;

  @media only screen and (max-width: 640px) {
    text-align: center;
  }
}

hr {
  margin: 15px 0 30px 0;
  height: 4px;
  color: #87173d;
  background-color: #87173d;
  border: 0;

  &.red {
    margin: 10px 0;
  }

  &.full {
    margin: 60px 0;
    height: 1px;
    color: #d9d9d9;
    background-color: #d9d9d9;
  }
}
.block-white hr.full.no-margin {
  margin: 20px -20px;
}



.block-white {
  background-color: #fff;
  border: 1px solid $color-border-grey;
  padding: 20px;
}
