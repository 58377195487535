.form-submit input[type="submit"] {
  color: #fff;
  background-color: $color-vw-burgundy;
  border: 0;
  width: 100%;
  padding: 19px 20px;
  font-size: 18px;
  font-weight: lighter;
  cursor: pointer;
  text-transform: uppercase;
}

.btn-download {
  color: #fff;
  background-color: $color-vw-burgundy;
  border: 0;
  padding: 11px 20px;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: left;

  span {
    display: block;
    font-size: 14px;
    background: url(/assets/images/i_download.png) no-repeat right center;
    color: #fff;
    font-weight: lighter;
    min-height: 25px;
    padding: 10px 0;
  }

  &:hover {
    text-decoration: none;
    color: #fff;
  }
}
