$path-fonts: 'fonts/';
$font-vw-head: 'Volkswagen Head', Arial, sans-serif;
$font-vw-text: 'Volkswagen Text', Arial, sans-serif;

$color-vw-blue:     #00405b;
$color-vw-burgundy: #87173d;
$color-border-grey: #e6e6e6;

@import "vendor/bootstrap";
@import "vendor/autocomplete";
@import "vendor/jquery-daterangepicker";
@import "vendor/chosen";
@import "utils/reset";
@import "utils/fonts";
@import "common";
@import "faq";
@import "utils/forms";
@import "utils/n-vert";
@import "pages/index";
@import "pages/groups";
