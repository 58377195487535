@font-face {
  font-family: 'Volkswagen Head';
  font-style: normal;
  font-weight: 100;
  src: url("#{$path-fonts}VWHeadWeb-Light.eot");
  src: url("#{$path-fonts}VWHeadWeb-Light.eot?#iefix") format("embedded-opentype"),
       url("#{$path-fonts}VWHeadWeb-Light.woff") format("woff"),
       url("#{$path-fonts}VWHeadWeb-Light.ttf") format("truetype"),
       url("#{$path-fonts}VWHeadWeb-Light.svg Head") format("svg");
}
@font-face {
  font-family: 'Volkswagen Head';
  font-style: normal;
  font-weight: normal;
  src: url("#{$path-fonts}VWHeadWeb-Regular.eot");
  src: url("#{$path-fonts}VWHeadWeb-Regular.eot?#iefix") format("embedded-opentype"),
       url("#{$path-fonts}VWHeadWeb-Regular.woff") format("woff"),
       url("#{$path-fonts}VWHeadWeb-Regular.ttf") format("truetype"),
       url("#{$path-fonts}VWHeadWeb-Regular.svg Head") format("svg");
}
@font-face {
  font-family: 'Volkswagen Head';
  font-style: italic;
  font-weight: normal;
  src: url("#{$path-fonts}VWHeadWeb-RegularItalic.eot");
  src: url("#{$path-fonts}VWHeadWeb-RegularItalic.eot?#iefix") format("embedded-opentype"),
       url("#{$path-fonts}VWHeadWeb-RegularItalic.woff") format("woff"),
       url("#{$path-fonts}VWHeadWeb-RegularItalic.ttf") format("truetype"),
       url("#{$path-fonts}VWHeadWeb-RegularItalic.svg Head") format("svg");
}
@font-face {
  font-family: 'Volkswagen Head';
  font-style: normal;
  font-weight: 700;
  src: url("#{$path-fonts}VWHeadWeb-Bold.eot");
  src: url("#{$path-fonts}VWHeadWeb-Bold.eot?#iefix") format("embedded-opentype"),
      url("#{$path-fonts}VWHeadWeb-Bold.woff") format("woff"),
      url("#{$path-fonts}VWHeadWeb-Bold.ttf") format("truetype"),
       url("#{$path-fonts}VWHeadWeb-Bold.svg Head") format("svg");
}
@font-face {
  font-family: 'Volkswagen Head';
  font-style: italic;
  font-weight: 700;
  src: url("#{$path-fonts}VWHeadWeb-BoldItalic.eot");
  src: url("#{$path-fonts}VWHeadWeb-BoldItalic.eot?#iefix") format("embedded-opentype"),
       url("#{$path-fonts}VWHeadWeb-BoldItalic.woff") format("woff"),
       url("#{$path-fonts}VWHeadWeb-BoldItalic.ttf") format("truetype"),
       url("#{$path-fonts}VWHeadWeb-BoldItalic.svg Head") format("svg");
}
@font-face {
  font-family: 'Volkswagen Head';
  font-style: normal;
  font-weight: 900;
  src: url("#{$path-fonts}VWHeadWeb-ExtraBold.eot");
  src: url("#{$path-fonts}VWHeadWeb-ExtraBold.eot?#iefix") format("embedded-opentype"),
       url("#{$path-fonts}VWHeadWeb-ExtraBold.woff") format("woff"),
       url("#{$path-fonts}VWHeadWeb-ExtraBold.ttf") format("truetype"),
       url("#{$path-fonts}VWHeadWeb-ExtraBold.svg Head") format("svg");
}

@font-face {
  font-family: 'Volkswagen Text';
  font-style: normal;
  font-weight: 100;
  src: url("#{$path-fonts}VWTextWeb-Light.eot");
  src: url("#{$path-fonts}VWTextWeb-Light.eot?#iefix") format("embedded-opentype"),
       url("#{$path-fonts}VWTextWeb-Light.woff") format("woff"),
       url("#{$path-fonts}VWTextWeb-Light.ttf") format("truetype"),
       url("#{$path-fonts}VWTextWeb-Light.svg") format("svg");
}
@font-face {
  font-family: 'Volkswagen Text';
  font-style: normal;
  font-weight: normal;
  src: url("#{$path-fonts}VWTextWeb-Regular.eot");
  src: url("#{$path-fonts}VWTextWeb-Regular.eot?#iefix") format("embedded-opentype"),
       url("#{$path-fonts}VWTextWeb-Regular.woff") format("woff"),
       url("#{$path-fonts}VWTextWeb-Regular.ttf") format("truetype"),
       url("#{$path-fonts}VWTextWeb-Regular.svg") format("svg");
}
@font-face {
  font-family: 'Volkswagen Text';
  font-style: italic;
  font-weight: normal;
  src: url("#{$path-fonts}VWTextWeb-RegularItalic.eot");
  src: url("#{$path-fonts}VWTextWeb-RegularItalic.eot?#iefix") format("embedded-opentype"),
       url("#{$path-fonts}VWTextWeb-RegularItalic.woff") format("woff"),
       url("#{$path-fonts}VWTextWeb-RegularItalic.ttf") format("truetype"),
       url("#{$path-fonts}VWTextWeb-RegularItalic.svg") format("svg");
}
@font-face {
  font-family: 'Volkswagen Text';
  font-style: normal;
  font-weight: 700;
  src: url("#{$path-fonts}VWTextWeb-Bold.eot");
  src: url("#{$path-fonts}VWTextWeb-Bold.eot?#iefix") format("embedded-opentype"),
       url("#{$path-fonts}VWTextWeb-Bold.woff") format("woff"),
       url("#{$path-fonts}VWTextWeb-Bold.ttf") format("truetype"),
       url("#{$path-fonts}VWTextWeb-Bold.svg") format("svg");
}
@font-face {
  font-family: 'Volkswagen Text';
  font-style: italic;
  font-weight: 700;
  src: url("#{$path-fonts}VWTextWeb-BoldItalic.eot");
  src: url("#{$path-fonts}VWTextWeb-BoldItalic.eot?#iefix") format("embedded-opentype"),
       url("#{$path-fonts}VWTextWeb-BoldItalic.woff") format("woff"),
       url("#{$path-fonts}VWTextWeb-BoldItalic.ttf") format("truetype"),
       url("#{$path-fonts}VWTextWeb-BoldItalic.svg") format("svg");
}
