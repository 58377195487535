/**
 * Page Groups: Login
 */
.page-groups--login {
  padding: 26px 20px 0 20px;

  .intro {
    margin: 20px 0;
    padding: 0;

    h1 {
      font-size: 30px;
      margin: 0;
    }
  }

  .form-groups-login {
    h2 {
      font-size: 18px;
      margin-bottom: 20px;
    }
    .form-group {
      margin-bottom: 20px;

      label {
        font-size: 18px;
        margin-bottom: 8px;
        display: block;
        font-family: $font-vw-head;
      }

      .control-form {
        display: block;
        width: 100%;
        background-color: #f2f2f2;
        border: 1px solid $color-border-grey;
        font-size: 18px;
        padding: 10px;
      }
    }
    .form-submit {
      input {
        color: #fff;
        text-transform: uppercase;
        background-color: $color-vw-burgundy;
        border: 0;
        width: 100%;
        padding: 10px;
        font-size: 18px;
        font-weight: lighter;
      }
    }
  }
}


/**
 * Page Groups: Dashboard
 */
.page-groups--dashboard {
  color: $color-vw-blue;

  .text-helper {
    color: #595959;
    font-style: italic;
  }

  select.form-control + .chosen-container-multi .chosen-choices {
    background-color: #f2f2f2;
    color: #00405b;
    border: 1px solid #e6e6e6;
    font-size: 18px;
    padding: 4px 10px;
  }
  .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    color: #00405b;
    font-family: $font-vw-head;
    margin: 0;
    padding: 0;
  }
  select.form-control + .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    margin: 0;
    padding: 0;
  }
  select.form-control + .chosen-container .chosen-results li.group-result {
    font-weight: bold;
    padding: 3px 15px;

    &:hover {
      background-color: #fff;
      color: #000;
    }
  }

  .groups-infos {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.025em;
    color: $color-vw-blue;
  }

  .block-white {
    margin-bottom: 10px;

    @media (min-width: 768px) {
      margin-bottom: 20px;
    }

    h2 {
      text-align: left;
    }
  }

  .block-download {
    select[name="brand"],
    button[name="download-selection"] {
      margin-bottom: 10px;
    }
    select[name="engine"] {
      margin-top: 10px;
    }
  }

  .filtered-vehicles-counter {
    font-size: 13px;
    font-weight: bold;
    margin-top: 10px;
  }

  .groups-infos--brand {
    margin: 10px 0;
    width: 50%;
    float: left;
    position: relative;
    min-height: 1px;
    // padding-left: 15px;
    // padding-right: 15px;

    @media (min-width: 768px) {
      width: 20%;
    }

    p {
      margin-bottom: 8px;
    }
  }

  .news {
    .news-news {
      border: 1px solid $color-border-grey;
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }

      .news-news--header-news {
        background: #f7f9fa url(/assets/images/i_calendar.png) no-repeat 13px center;
        border-bottom: 1px solid $color-border-grey;
        vertical-align: middle;
        font-weight: 100;
        font-size: 14px;
        text-transform: uppercase;
        color: #757575;
        padding: 8px 20px 8px 40px;
        cursor: pointer;
        position: relative;
        .news-news--header-collapsed-btn {
          position: absolute;
          top: 3px;
          right: 8px;
          display: none;
          background-color: #ebf0f2;
          border: none;
          color: #646464;
          outline: none;
          width: 25px;
          height: 25px;
        }
        .news-news--header-expanded-btn {
          display: inline-block;
          position: absolute;
          top: 3px;
          right: 8px;
          background-color: #ebf0f2;
          border: none;
          color: #646464;
          outline: none;
          width: 25px;
          height: 25px;
        }
        &.collapsed {
          .news-news--header-expanded-btn {
            display: none;
          }
          .news-news--header-collapsed-btn {
            display: inline-block;
          }
          &.collapsed-no-icon {
            a {
              background: #fff url(/assets/images/i_download.png) no-repeat 9px center;
            }
          }
        }
      }
      .news-news--header-docs {
        background: #fff url(/assets/images/i_download.png) no-repeat 9px center;
        &.collapsed-no-icon {
          background: #fff;

        }
        border-bottom: 1px solid $color-border-grey;
        vertical-align: middle;
        font-weight: 100;
        font-size: 14px;
        text-transform: uppercase;
        color: #757575;
        padding: 8px 40px 8px 40px;
        cursor: pointer;
        position: relative;
        .news-news--header-collapsed-btn {
          position: absolute;
          top: 9%;
          right: 8px;
          display: none;
          background-color: #ebf0f2;
          border: none;
          color: #646464;
          outline: none;
          width: 25px;
          height: 25px;
        }
        .news-news--header-expanded-btn {
          display: inline-block;
          position: absolute;
          top: 9%;
          right: 8px;
          background-color: #ebf0f2;
          border: none;
          color: #646464;
          outline: none;
          width: 25px;
          height: 25px;
        }
        &.collapsed {
          .news-news--header-expanded-btn {
            display: none;
          }
          .news-news--header-collapsed-btn {
            display: inline-block;
          }
          &.collapsed-no-icon {
            background: #fff url(/assets/images/i_download.png) no-repeat 9px center;
          }
        }
      }
      .news-news--content {
        color: $color-vw-blue;
        font-size: 18px;
        font-weight: bold;
        padding: 10px;
      }
      .news-news--text {
        color: $color-vw-blue;
        font-size: inherit;
        font-weight: normal;
        padding: 10px 10px;
        .news-news--download-link {
          a, a:link, a:visited {
            display: block;
            width: 95%;
            padding: 10px 0px;
            margin: auto;
            font-style: italic;
            font-weight: 100;
            color: $color-vw-blue;
          }
        }
        &.icon-link {
          .news-news--download-link {
            a {
              background: #fff url(/assets/images/i_download.png) no-repeat 0px center;
              padding-left: 30px;
            }
          }
        }
      }
      .news-news--description {
        color: $color-vw-blue;
        font-size: 14px;
        font-weight: normal;
        padding: 10px;
        line-height: 18px;
      }
      .news-news--link {
        font-size: 12px;
        padding: 0 10px 10px;

        a {
          color: $color-vw-burgundy;
        }
      }
    }
  }

  label.label-mini {
    margin-bottom: 8px;
    font-size: 12px;
  }

  .groups-dashboard--form-vin {
    label {
      margin-bottom: 8px;
      font-size: 12px;
    }
    .autocomplete-vin-results {
      display: none;

      table {
        min-width: 1600px;
      }

      thead {
        td {
          font-size: 15px;
          color: $color-vw-blue;
        }
      }
      tbody {
        td {
          color: #757575;
          font-size: 14px;
          min-width: 100px;
        }
      }
    }
  }

  .help-text {
    font-size: 11px;
    margin: 10px 0;
    background: url(/assets/images/i_info_blue.png) no-repeat 0 0;
    padding-left: 46px;
    display: block;
    min-height: 25px;
  }

  .form-control {
    width: 100%;
    background-color: #f2f2f2;
    color: $color-vw-blue;
    border: 1px solid $color-border-grey;
    font-size: 18px;
    padding: 6px 10px;
    border-radius: 0;
  }



}
