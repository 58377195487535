.page-index {
  section.com {
    h2 {
      font-size: 28px;
    }
    p.intro_com {
      font-size: 16px;
    }
  }
  section.form label.opttext {
    font-size: 16px;
  }
  section.form label.opttext .radio-wrapper {
    margin-left: 20%;
  }
  section.form label.opttext .radio-txt {
    width: calc(80% - 30px);
  }
  section.form p {
    font-size: 16px;
  }
}
