.nvert_block{
  background-color: #fff;
  padding: 30px;
  font-size: 0;
  margin-bottom: 40px;
}

.nvert_block.nvertfooter{
  margin-bottom: 0;
}

.tel_nvert,
.chat_nvert{
  width: 45%;
  height: 120px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 2.5%;
  border: 1px solid #e6e6e6;
}

@media only screen and (max-width: 1200px){
  .tel_nvert,
  .chat_nvert{
    width: 100%;
    display: block;
    margin: 20px auto;
  }
}

@media only screen and (max-width: 520px){
  .tel_nvert,
  .chat_nvert{
    height: 150px;
  }
}

@media only screen and (max-width: 360px){
 .tel_nvert,
 .chat_nvert{
  height: auto;
  text-align: center;
}
}

.icon_telnvert,
.icon_chatnvert{
  width: 25%;
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  border-right: 1px solid #e6e6e6;
  text-align: center;
}

@media only screen and (max-width: 360px){
 .icon_telnvert,
 .icon_chatnvert{
  width: 100%;
  height: auto;
  display: block;
  border-right: none;
  border-bottom: 1px solid #e6e6e6;
}
}

.icon_telnvert img{
  margin-top: 30px;
}

.icon_chatnvert img{
  margin-top: 40px;
}

@media only screen and (max-width: 360px){
  .icon_telnvert,
  .icon_chatnvert{
    padding: 10px 0;
    margin: 0 auto;
  }

  .icon_telnvert img,
  .icon_chatnvert img{
    margin: 0;
  }
}

.icon_telnvert span,
.icon_chatnvert span{
  display: block;
  font-weight: normal;
  font-size: 12px;
  margin-top: 10px;
}

.txt_telnvert,
.txt_chatnvert{
  width: 75%;
  padding: 0 10px;
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  font-size: 12px;
  color: #00405b;
}

.txt_telnvert strong,
.txt_chatnvert strong{
  font-weight: bold;
}

@media only screen and (max-width: 360px){
  .txt_telnvert,
  .txt_chatnvert{
    padding: 10px 0;
  }
}

.nvert{
  margin-top: 10px;
  border: 1px solid #e6e6e6;
}

.nvert .num_nvert{
  width: 60%;
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
  color: #669933;
  font-size: 18px;
  padding-left: 5px;
}
.nvert .txt_nvert{
  padding: 7px;
  width: 40%;
  display: inline-block;
  vertical-align: middle;
  background-color: #669933;
  color: #fff;
  font-size: 12px;
}

@media only screen and (max-width: 1200px){
  .nvert .num_nvert{
    font-size: 22px;
    padding-left: 10px;
  }

  .nvert .txt_nvert{
    padding: 15px;
  }
}

@media only screen and (max-width: 520px){
  .nvert .num_nvert{
    display: block;
    width: 100%;
    padding: 5px;
  }

  .nvert .txt_nvert{
    display: block;
    width: 100%;
    padding: 7px;
  }
}

.n_vert{
  text-align: center;
  padding: 20px 0 0;
  background-color: #fff;
}

.n_vert h3 {
  text-align: center;
  color: #00405b;
  font-size: 24px;
  font-family: 'Volkswagen Head';
  font-weight: 100;
  padding-bottom: 20px;
}
